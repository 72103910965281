<template>
<div>
    <div v-if="isLoading">
      <CanPayLoader/>
    </div>
  <div class="container updated-transaction-container">
    <div v-if="showAcceptedHistory" :class="accepted_data.length > 1 ? 'updated-transaction-slider' : 'updated-transaction-slider w-100'">
      <div 
      v-for="(accept_data, index) in accepted_data"
      :key="index"
      :class="Object.keys(accepted_data).length > 1 ? 'updated-transaction-slider-card position-relative' : 'updated-transaction-slider-card position-relative mr-0 w-100'"
      >
        <div class="updated-transaction-slider-card-header">
            <h4>Prepayment Accepted Alert</h4>
        </div>
        <div class="updated-transaction-slider-card-body">
          <div class="modification_text_group text-left">
            <p><span class="text-black">Merchant Name :</span> <span class="text-muted">{{accept_data.store_name}}</span></p>
            <p><span class="text-black">Merchant Phone :</span> <a :href="'tel:'+accept_data.store_contact_no">{{accept_data.store_contact_no}}</a></p>
          </div>
          <div class="modification_text_group text-left">
              <p v-if="accept_data.before_modification_amount"><span class="text-black">Orginal Amount :</span> <span class="text-muted">${{(parseFloat(accept_data.before_modification_amount).toFixed(2))}}</span> </p>
              <p><span class="text-black">Final Amount:</span> <span class="text-muted">${{parseFloat(accept_data.transaction_amount).toFixed(2)}}</span> </p>
              <p><span class="text-black">Approved By Merchant:</span> <span class="text-muted">{{transactiontime(accept_data)}}</span></p>
              <p><span class="text-black">Expected Debit Date:</span> <span class="text-muted">{{expectedDebitDate(accept_data)}}</span></p>
          </div>
          <button type="button" class="updated-transaction-slider-btn btn btn-success thnku-btn" @click="setdata(accept_data.transaction_id)" >Thanks For Letting Me Know</button>
        </div>
      </div>
    </div>


  </div>
</div>
</template>
<script>
import { db } from "../firebaseConfig.js";
import Loading from "vue-loading-overlay";
import VueLoadImage from "vue-load-image";
import moment from "moment";
import CanPayLoader from "./CustomLoader/CanPayLoader.vue"
export default {
  name: "AcceptedTransactionDetails",
  data() {
    return {
      isLoading : false,
      fullPage : true,
      showAcceptedHistory : true,
      pay_redirect : true,
      accepted_data : [],
    };
  },
  created() {
    this.currentUser = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;
    this.getdata();
  },
  components: {
    "vue-load-image": VueLoadImage,
    Loading,
    CanPayLoader
  },
  methods: {
    transactiontime(up_data) {
      return moment
        .utc(up_data.transaction_time)
        .local()
        .format(" DD MMM, YYYY | hh:m A");
    },
    expectedDebitDate(up_data) {
      console.log(up_data);
      return moment
        .utc(up_data.expected_debit_date)
        .local()
        .format(" DD MMM, YYYY");
    },
    getdata() {
      let self = this;
      self.accepted_data = [];
      let ref = db
        .collection("users")
        .doc(String(String(this.currentUser.user_id)));
      ref.get().then((snapshot) => {
        ref.onSnapshot((convo) => {
          let source = convo.metadata.hasPendingWrites ? "Local" : "Server";
          // TODO: add messages to store
          self.pay_redirect = true;
          let ref = db
            .collection("users")
            .doc(String(this.currentUser.user_id));
          ref.get().then((snapshot) => {
            if (snapshot.exists) {
              this.users = snapshot.data();
              const containsKey = (obj, key) => Object.keys(obj).includes(key);
              const hasPostToBankName = containsKey(this.users, "post_to_bank");
              if (hasPostToBankName == true) {
                if (this.users.post_to_bank != null) {
                  if (Object.keys(this.users.post_to_bank).length > 0) {
                    self.accepted_data = this.users.post_to_bank;
                    self.pay_redirect = false;
                  }
                }
              }

            }
            if(self.pay_redirect){
              self.$router.push("/pay");
            }
          });
        });
      });
    },
    setdata(tr_id) {
      this.$delete(this.accepted_data, tr_id)
      var data = {
        post_to_bank: this.accepted_data
      };
      var washingtonRef = db
        .collection("users")
        .doc(String(this.currentUser.user_id));
      // Set the "capital" field of the city 'DC'
      return washingtonRef
        .update(data)
        .then(function () {
          console.log("Document successfully updated!");
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
  },
  mounted() {
    var element = document.getElementsByClassName("content-wrap");
    if (element[0]) {
      element[0].style.setProperty("background-color", "#149240");
      element[0].style.height = "114vh";
      if(window.innerWidth>1200){
        element[0].style.height = "121vh";
      }
    }
    this.$root.$emit("loginapp", [""]);
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
  },
};
</script>
<style lang="scss" scoped>
.updated-transaction-container{
  height: calc(100vh - 100px);
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .updated-transaction-container{
    height: calc(100vh - 150px);
  }
}
.updated-transaction-slider {
  // height: 325px;
  /* line them up horizontally */
  display: flex;

  /* allow for scrolling */
  overflow-x: auto;

  /* make it smooth on iOS */
  -webkit-overflow-scrolling: touch;
  scroll-snap-points-x: repeat(300px);
  scroll-snap-type: mandatory;
}
.updated-transaction-slider > div {
  /* make sure the width is honored */
  flex-shrink: 0;
  width: 300px;
  // height: 300px;
  // line-height: 300px;
  text-align: center;
  background: #ffffff;
  margin-right: 15px;
  border-radius: 5px;
}
.updated-transaction-slider-btn{
  position: absolute;
  bottom: 13px;
  left: 50%;
  width: 90% !important;
  transform: translateX(-50%);
}

.updated-transaction-slider-card-header{
  background-color: #000;
  padding: 20px 15px;
  border-radius: 6px 6px 0 0 ;
  font-size: 15px;
}
.updated-transaction-slider-card-header h4{
  color: #fff;
  margin-bottom: 0;
}
.updated-transaction-slider-card-body{
  padding: 15px;
  border-radius: 0 0 6px 6px ;
  min-height: 300px;
}
.foot-btn{
    position: absolute;
    bottom: 0;
    left: 7px;
    width: 95%!important;
}
.modification_text_group{
  margin-bottom: 1.1rem;
}
.modification_text_highlight{
  background-color: #80808026;
  text-align: center;
  border-radius: 0.25rem;
}
.modification_text_highlight .timer{
  font-size: 1.3rem;
}
.modification_text_group p{
  margin-bottom: 0.1rem;
}
#accept-reject-alert-model___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#accept-reject-alert-model___BV_modal_body_ {
  background-color: #ffffff!important;
  border-radius: 12px;
  margin: 10px;
}
#accept-reject-alert-model___BV_modal_body_ .update-modal-title {
  font-size: 1.1rem;
}
#accept-reject-alert-model___BV_modal_body_ .modal-tip {
  font-size: 0.9rem;
}
.amount_input{
  width: 100%;
  padding: 0px 30px !important;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.doller_icon{
  position: absolute;
  left: 30px;
  top: 12px;
  color: gray;
}
.thnku-btn{
  color: #fff;
  background-color: #149240;
  border-color: #149240;
  width: 100%;
  height: 50px;
  margin: 0;
  border-radius: 5px;
}
</style>